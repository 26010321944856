import 'leaflet/dist/leaflet.css';
import blueIcon from './icons/blue.png';
import redIcon from './icons/red.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import postcodes from './data/postcodes.json';
import { useMemo, useRef, useState } from 'react';

const BlueIcon = L.icon({
  iconUrl: blueIcon,
  shadowUrl: iconShadow,
});

const RedIcon = L.icon({
    iconUrl: redIcon,
    shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = BlueIcon;

function App() {
  const inputRef = useRef();
  const position = [62.4728, 21.3372];
  const [selectedPostcodes, setSelectedPostCodes] = useState([]);
  const [excludedPostcodes, setExcludedPostCodes] = useState([]);

  const toggleSelected = code => setSelectedPostCodes(
    codes => codes.indexOf(code) === -1
      ? [...codes, code].sort((a, b) => a - b)
      : codes.filter(c => code !== c),
  );

  const filteredPostcodes = useMemo(
    () => postcodes.filter(({ postcode }) => excludedPostcodes.indexOf(postcode) === -1),
    [excludedPostcodes],    
  );

  return (
    <div style={{ flex: 1, flexDirection: 'column', display: 'flex' }}>
      <div style={{ flexGrow: 1, display: 'flex' }}>
        <MapContainer
          center={position}
          zoom={13}
          scrollWheelZoom={false}
          style={{ flex: 1 }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {filteredPostcodes.map(({ x, y, postcode }) => {
            return (
              <Marker
                key={postcode}
                position={[x, y]}
                icon={selectedPostcodes.indexOf(postcode) === -1 ? BlueIcon : RedIcon}
                eventHandlers={{
                  click: () => toggleSelected(postcode)
                }}
              />
            );
          })}
        </MapContainer>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
        <div style={{ display: 'flex' }}>
          <div>
            Valda postnummer:
          </div>
          <div style={{ flex: 1 }}>
            <input
              type="text"
              style={{width: '100%'}}
              ref={inputRef}
              value={selectedPostcodes.filter(c => excludedPostcodes.indexOf(c) === -1).join(';')}
              disabled
            />
          </div>
          <div>
            <button type="button" onClick={() => navigator.clipboard.writeText(inputRef.current.value)}>Kopiera</button>
          </div>
          <div>
            <button type="button" onClick={() => setSelectedPostCodes([])}>Töm</button>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div>
            Dölj postnummer:
          </div>
          <div style={{ flex: 1 }}>
            <input
              type="text"
              onChange={({ target: { value }}) => setExcludedPostCodes(value.split(';'))}
              style={{width: '100%'}}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
